import React, { useState } from "react"
import { formatCurrency, formatDate } from '../../src/utils'
import Button from '../Button'
import CurrencyField from '../CurrencyField'
import { VehicleLineItem } from "../types"
import { ActionType } from '../types/actions'
import PaymentRow from "./PaymentRow"
import ProductLineItemForm, { TargetTypes } from "./ProductLineItemForm"
import { useReservationContext } from './Provider'

interface LineItemProps {
    index: number
    lineItem: VehicleLineItem
}

export default function LineItem({ index, lineItem }: LineItemProps) {

    const { state, dispatch, cancelTransaction, updateDriver } = useReservationContext()

    const [showDriverSelect, setShowDriverSelect] = useState(lineItem.driver == null)

    async function handleDriverChange(e) {
        if (e.target.value > -1) {
            /*dispatch({
                type: ActionType.SelectDriver,
                id: e.target.value,
                lineItemIndex: index
            })*/
            await updateDriver(e.target.value, index)
        }
    }


    let lineItemSubTotal = lineItem.lineItems.reduce((acc, addOnLineItem) => {
        return acc += (addOnLineItem.subTotal)
    }, 0) + lineItem.unitPrice

    return (
        <div key={index} className="grid grid-cols-1 gap-4 mb-3 pb-5 border-b border-gray-600">
            <div className="flex">
                <div>
                    <div className="font-bold">{formatDate(lineItem.trackDate.date)}</div>
                    <div>{lineItem.trackDate.track.name}</div>
                    <div>Organizer: {lineItem.trackDate.trackDateGroup.eventOrganizer}</div>
                    <div>{lineItem.trackDateVehicle.vehicle.name}</div>

                    {lineItem.driver && <div className="flex w-full mt-2">
                        <div className="border-l-2 pl-2 border-gray-600">
                            <div>
                                Driver: {lineItem.driver.firstName} {lineItem.driver.lastName}
                            </div>
                            <div className="pl-3 text-xs my-3">
                                <div>Phone: {lineItem.driver.phone}</div>
                                <div>Email: {lineItem.driver.email}</div>
                                <div>Emergency Contact: {lineItem.driver.emergencyContact}</div>
                                <div>Emergency Phone: {lineItem.driver.emergencyPhone}</div>
                                <div>Experience Level: {lineItem.driver.experienceLevel}</div>
                                <div>License: {lineItem.driver.licenseNumber}</div>
                                <div>D.O.B: {lineItem.driver.dob}</div>
                            </div>
                        </div>
                        <div className="flex gap-5">
                            <a onClick={() => {
                                dispatch({
                                    type: ActionType.EditDriver,
                                    driver: lineItem.driver,
                                    lineItemIndex: index
                                })
                            }} className="ml-auto text-primary-600 text-sm cursor-pointer">Edit Driver</a>

                            <a onClick={() => {
                                setShowDriverSelect(!showDriverSelect)
                            }} className="ml-auto text-primary-600 text-sm cursor-pointer">Change Driver</a>
                        </div>
                    </div>}


                    {<div className="mt-3 flex items-center">



                        {showDriverSelect && <>

                            {(state.customer && state.customer.drivers.length > 0) && <div className="mr-3">

                                <div>
                                    <div>
                                        <select className="form-control" onChange={handleDriverChange}>
                                            <option value={-1}>Select an Existing Driver</option>
                                            {state.customer.drivers.map((driver) => {
                                                return (
                                                    <option value={driver.id}>{driver.firstName} {driver.lastName}</option>
                                                )
                                            })}
                                        </select>
                                    </div>

                                </div>


                            </div>}
                            <div>
                                <a onClick={() => {
                                    dispatch({
                                        type: ActionType.AddDriver,
                                        lineItemIndex: index
                                    })
                                }} className="btn btn-primary">
                                    Add {lineItem.driver && <span>&nbsp;Another</span>} Driver
                                </a>
                            </div>

                        </>}

                    </div>}


                </div>
                {(state.editable && !state.locked) ? <div className="ml-auto w-32">

                    <CurrencyField onChange={(e) => {
                        dispatch({
                            type: ActionType.UpdateLineItemPrice,
                            lineItemIndex: index,
                            price: e.target.value ? parseFloat(e.target.value) : ''
                        })
                    }} className="text-black w-20 rounded-md p-2 text-center" value={lineItem.unitPrice} />

                    {lineItem.originalUnitPrice &&
                        <div className="text-xs text-yellow-200 my-1 text-center">Original Price:<br /> {formatCurrency(lineItem.originalUnitPrice)}</div>
                    }
                </div>
                    :
                    <div className="ml-auto w-32 text-right">

                        {formatCurrency(lineItem.unitPrice)}

                    </div>
                }
            </div>
            <div>

                <div className="p-2 rounded-xl">
                    <label>Addons</label>
                    <ProductLineItemForm editable={state.editable && !state.locked} target={TargetTypes.Reservation} lineItemIndex={index} />

                    {(lineItem.transactions && lineItem.transactions.length > 0) &&
                        <div className="my-10">
                            <label>Additional Transactions</label>
                            {lineItem.transactions?.map((transaction) => {

                                let payment = null
                                let allowCancel = false
                                let allowRefund = false

                                if (transaction.payments.length > 0) {
                                    payment = transaction.payments[0]
                                }

                                return (
                                    <div className="text-sm mb-3 rounded-md border border-gray-700">
                                        <div className="flex bg-gray-700 text-white p-2">
                                            <div className="mr-2">
                                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5 2a2 2 0 00-2 2v14l3.5-2 3.5 2 3.5-2 3.5 2V4a2 2 0 00-2-2H5zm2.5 3a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm6.207.293a1 1 0 00-1.414 0l-6 6a1 1 0 101.414 1.414l6-6a1 1 0 000-1.414zM12.5 10a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" clipRule="evenodd" /></svg>
                                            </div>
                                            <div>Transaction #{transaction.id}</div>
                                            <div className="ml-auto text-gray-200 flex items-center">
                                                {formatDate(transaction.createdAt)}
                                                {!payment &&
                                                    <div className="ml-3">
                                                        <Button onClick={() => {
                                                            cancelTransaction(transaction)
                                                        }} xs>Cancel</Button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            {transaction.lineItems.map((tLineItem) => {
                                                return (
                                                    <div className="flex items-center p-2">
                                                        <div className="w-7/12">{tLineItem.trackDateProduct.product.title}</div>
                                                        <div className="w-2/12 flex items-center">
                                                            <span>{formatCurrency(tLineItem.unitPrice)}</span>
                                                            <div className="mx-2">x</div>
                                                        </div>
                                                        <div className="w-1/12">{tLineItem.quantity}</div>
                                                        <div className="w-1/12">{formatCurrency(tLineItem.subTotal)}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>


                                        {payment && <>
                                            <div className="p-2 mb-5">
                                                <div className="text-xs text-center text-gray-200 border-b border-gray-700 p-1 uppercase">Payments</div>
                                                <div>
                                                    <PaymentRow payment={payment} />
                                                </div>
                                            </div>
                                            {(allowCancel || allowRefund) && <div className="p-2 mb-5 flex">
                                                <div className="ml-auto flex">

                                                    {allowCancel && <div className="mr-2">
                                                        <Button xs>Cancel</Button>
                                                    </div>}

                                                    {allowRefund && <div>
                                                        <Button xs onClick={() => {
                                                            dispatch({ type: ActionType.BeginRefund, payment: payment })
                                                        }}>
                                                            Refund
                                                        </Button>
                                                    </div>}

                                                </div>
                                            </div>}
                                        </>}


                                    </div>
                                )
                            })}
                        </div>
                    }

                    {state.locked &&
                        <div className="my-5">
                            <Button onClick={() => {
                                dispatch({
                                    type: ActionType.ToggleNewTransaction,
                                    lineItemIndex: index
                                })
                            }}>New Transaction</Button>
                        </div>
                    }

                </div>

            </div>

            <div className="flex items-center">
                {(state.editable && !state.locked) && <div className="text-red-500 cursor-pointer hover:text-red-800 text-sm uppercase" onClick={() => {
                    dispatch({
                        type: ActionType.RemoveLineItem,
                        lineItemIndex: index
                    })
                }}>
                    Remove
                </div>}

                <div className="ml-auto text-right text-lg font-bold">
                    Sub Total: {formatCurrency(lineItemSubTotal)}
                </div>
            </div>
        </div>
    )
}
